// src/components/reports/MobileReportMenu.js

import React, { useState } from 'react'
import { LargeLabel } from '../../templates/label/Labels'
import { GenericElement } from '../../templates/FormElement'
import { RefUserAttributesHook } from '../hooks/UserAttributesHook';
import ResultsSummary from './ResultsSummary';
import InputsSummary from './InputsSummary';
import PurchaseReport from './PurchaseReport';
import PositionReport from './PositionReport';
import ForwardAssessmentReport from './ForwardAssessmentReport';

// Importaciones globales
import * as con from '../../GlobalConstants'
import { IsStaff } from '../hooks/AuthenticationHook';
import CommitteeReport from './CommitteeReport';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: con.USABLE_SCREEN_SIZE + "vh",
};

const logoStyle = {
    marginTop: "20px",
    marginBottom: "20px",
    width: "80%",
    maxWidth: "300px",
};

const reportSlideStyle = {
    width: "90%",
};

const ReportSelector = ({ report }) => {

    switch (report) {
        case con.BAND_SUMMARY_REPORT:
            return <ResultsSummary />
        case con.CURRENT_FLOW_REPORT:
            return <InputsSummary />
        case con.PURCHASE_REPORT:
            return <PurchaseReport />
        case con.POSITION_REPORT:
            return <PositionReport />
        case con.ASSESSMENT_FWD_REPORT:
            return <ForwardAssessmentReport />
        case con.COMMITTEE_REPORT:
            return <CommitteeReport />
        default:
            return null

    }
}

const availableReports = (isStaff) => {
    const reportKeys = [];
    const reportNames = [];

    for (const key in con.AVAILABLE_REPORTS) {
        if (isStaff || !(con.AVAILABLE_REPORTS[key][con.STAFF])) {
            reportKeys.push(key);
            reportNames.push(con.AVAILABLE_REPORTS[key][con.NAME])
        }
    }
    return { reportKeys, reportNames };
}

function MobileReportMenu() {
    const [selectedReport, setSelectedReport] = useState(() => "")
    const avatarImage = RefUserAttributesHook(con.AVATAR_IMAGE)
    const { reportKeys, reportNames } = availableReports(IsStaff())

    return (
        <div style={containerStyle}>
            <img src={avatarImage} alt="Avatar" style={logoStyle} />
            {/* Eliminamos el segundo logo */}
            {/* <img src={logo} alt="Logo" style={logoStyle} /> */}
            <div style={reportSlideStyle}>
                <LargeLabel text="DESCARGAR REPORTES" />

                <GenericElement type={con.MULTIPLE_SELECTION}
                    name={'¿Qué reporte desea descargar?'}
                    value={selectedReport}
                    setValue={setSelectedReport}
                    values={reportKeys}
                    valueLabels={reportNames}
                />
                <ReportSelector report={selectedReport} />
            </div>
        </div>
    )
}

export default MobileReportMenu
