// TODO - Documentar
import React from 'react'

import Scenarios from '../components/scenarios/Scenarios'
import MarketSummaries from '../components/market/MarketSummaries'
import CustomScenario from '../components/scenarios/CustomScenario'
import { PROPORTIONAL_HEIGHT, PROPORTIONAL_WIDTH } from '../templates/charts/ChartConstants'
import "./MobilePages.css"

function MobileScenariosPage() {

    return (
        <div style={{ marginTop : "10px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>            
                <Scenarios chartParameters={{[PROPORTIONAL_WIDTH] : 320,
                                             [PROPORTIONAL_HEIGHT] : 240}}/>                       
                <CustomScenario />
                <MarketSummaries />
        </div>
    )
}

export default MobileScenariosPage
