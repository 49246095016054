// CandleStickChart.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { DateTime } from 'luxon';
import { Switch } from '@blueprintjs/core';

function calculateMovingAverage(data, length = 20) {
    const maData = [];
    for (let i = 0; i < data.length; i++) {
        if (i < length) {
            maData.push({ time: data[i].time });
        } else {
            let sum = 0;
            for (let j = 0; j < length; j++) {
                sum += data[i - j].close;
            }
            const maValue = sum / length;
            maData.push({ time: data[i].time, value: maValue });
        }
    }
    return maData;
}

const CandleStickChart = ({ data, alerts }) => {
    const chartContainerRef = useRef();
    const chart = useRef();
    const priceLines = useRef([]);
    const maSeriesRef = useRef(null);
    const toolTipRef = useRef(null);

    const [interval, setIntervalType] = useState(15); 
    const [showAlerts, setShowAlerts] = useState(false);
    const [showMA, setShowMA] = useState(false); 
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 768 && window.innerWidth <= 1440);

    const [hoveredInterval, setHoveredInterval] = useState(null);

    const [hovered, setHovered] = useState(false); 

    const getChartHeight = useCallback(() => {
        return Math.min(
            Math.floor(
                window.innerHeight * (isSmallScreen ? 0.22 : isMediumScreen ? 0.3 : 0.4)
            ),
            400
        );
    }, [isSmallScreen, isMediumScreen]);

    const formatDataForChart = useCallback((data, interval) => {
        const priceData = [];

        const dataArray = data.dates.map((dateStr, index) => {
            const date = DateTime.fromISO(dateStr).minus({ hours: 5 }).toMillis();
            return {
                date: date,
                close: data.close[index],
            };
        });

        dataArray.sort((a, b) => a.date - b.date);

        let currentInterval = null;

        for (const dataPoint of dataArray) {
            const date = dataPoint.date;
            const intervalStart = Math.floor(date / (interval * 60 * 1000)) * (interval * 60 * 1000);

            if (!currentInterval || currentInterval.intervalStart !== intervalStart) {
                if (currentInterval) {
                    priceData.push({
                        time: Math.floor(currentInterval.intervalStart / 1000),
                        open: currentInterval.open,
                        high: currentInterval.high,
                        low: currentInterval.low,
                        close: currentInterval.close,
                    });
                }

                currentInterval = {
                    intervalStart,
                    open: dataPoint.close,
                    high: dataPoint.close,
                    low: dataPoint.close,
                    close: dataPoint.close,
                };
            } else {
                currentInterval.high = Math.max(currentInterval.high, dataPoint.close);
                currentInterval.low = Math.min(currentInterval.low, dataPoint.close);
                currentInterval.close = dataPoint.close;
            }
        }

        if (currentInterval) {
            priceData.push({
                time: Math.floor(currentInterval.intervalStart / 1000),
                open: currentInterval.open,
                high: currentInterval.high,
                low: currentInterval.low,
                close: currentInterval.close,
            });
        }

        return priceData;
    }, []);

    const updateMASeries = useCallback((show, priceData) => {
        if (!maSeriesRef.current && chart.current) {
            maSeriesRef.current = chart.current.addLineSeries({ color: '#2962FF', lineWidth: 1 });
        }

        const maData = calculateMovingAverage(priceData, 20);
        if (maSeriesRef.current) {
            maSeriesRef.current.setData(maData);
            maSeriesRef.current.applyOptions({ visible: show });
        }
        if (chart.current) {
            chart.current.timeScale().fitContent();
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsSmallScreen(width <= 768);
            setIsMediumScreen(width > 768 && width <= 1440);

            if (chart.current && chartContainerRef.current) {
                chart.current.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: getChartHeight(),
                });
                chart.current.timeScale().fitContent();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getChartHeight]);

    useEffect(() => {
        if(!chartContainerRef.current) return;
        const container = chartContainerRef.current;

        chart.current = createChart(container, {
            width: container.clientWidth,
            height: getChartHeight(),
            layout: {
                background: { color: '#131623' }, 
                textColor: '#DDD',
                fontSize: 12,
                fontFamily: 'Arial',
            },
            grid: {
                vertLines: {
                    color: '#444',
                },
                horzLines: {
                    color: '#444',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            watermark: {
                visible: true,
                fontSize: 88,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(255, 255, 255, 0.1)',
                text: 'L U M E N',
            },
        });

        chart.current.priceScale().applyOptions({
            borderColor: '#71649C',
        });

        chart.current.timeScale().applyOptions({
            borderColor: '#71649C',
            timeVisible: true,
            secondsVisible: false,
        });

        chart.current.candleSeries = chart.current.addCandlestickSeries({
            upColor: 'rgb(50, 168, 82)',     
            downColor: 'rgb(225, 50, 85)',   
            borderVisible: false,            
            wickUpColor: 'rgb(50, 168, 82)', 
            wickDownColor: 'rgb(225, 50, 85)', 
        });
        
        toolTipRef.current = document.createElement('div');
        toolTipRef.current.style = `
            position: absolute;
            display: none;
            padding: 8px;
            box-sizing: border-box;
            font-size: 12px;
            text-align: left;
            z-index: 1000;
            pointer-events: none;
            border: 1px solid #2962FF;
            border-radius: 2px;
            background: white;
            color: black;
            font-family: Arial;
        `;
        container.appendChild(toolTipRef.current);

        const crosshairMoveHandler = (param) => {
            if (
                !param.point ||
                !param.time ||
                !container ||
                param.point.x < 0 ||
                param.point.x > container.clientWidth ||
                param.point.y < 0 ||
                param.point.y > container.clientHeight
            ) {
                if (toolTipRef.current) {
                    toolTipRef.current.style.display = 'none';
                }
                return;
            }

            const candleData = param.seriesData.get(chart.current.candleSeries);
            if (!candleData) {
                if (toolTipRef.current) {
                    toolTipRef.current.style.display = 'none';
                }
                return;
            }
            const price = candleData.close !== undefined ? candleData.close : candleData.value;
            const date = new Date(param.time * 1000); 
            const dateStr = date.toLocaleString(); 

            if (toolTipRef.current) {
                toolTipRef.current.style.display = 'block';
                toolTipRef.current.innerHTML = `
                    <div style="color:#2962FF">Precio</div>
                    <div style="font-size: 24px; margin: 4px 0px; color:black;">${Math.round(price * 100)/100}</div>
                    <div style="color:black;">${dateStr}</div>
                `;
            }

            const coordinate = chart.current.candleSeries.priceToCoordinate(price);
            if (coordinate === null || !container) return;
            
            let toolTipWidth = 100;
            let toolTipHeight = 80;
            let toolTipMargin = 15;
            
            let x = param.point.x - (toolTipWidth / 2);
            x = Math.max(0, Math.min(container.clientWidth - toolTipWidth, x));

            let y = coordinate - toolTipHeight - toolTipMargin;
            if (y < 0) {
                y = coordinate + toolTipMargin;
            }

            if (toolTipRef.current) {
                toolTipRef.current.style.left = x + 'px';
                toolTipRef.current.style.top = y + 'px';
            }
        };

        chart.current.subscribeCrosshairMove(crosshairMoveHandler);

        const resizeObserver = new ResizeObserver(() => {
            if (!container) return;
            chart.current.applyOptions({ width: container.clientWidth, height: getChartHeight() });
            setTimeout(() => {
                if (chart.current) {
                    chart.current.timeScale().fitContent();
                }
            }, 0);
        });
        resizeObserver.observe(container);

        return () => {
            resizeObserver.disconnect();
            if (toolTipRef.current && container && toolTipRef.current.parentNode === container) {
                container.removeChild(toolTipRef.current);
            }
            if (chart.current) {
                chart.current.remove();
            }
        };
    }, [getChartHeight]);

    useEffect(() => {
        if (!chart.current || !data || !data.dates || data.dates.length === 0) return;
    
        const priceData = formatDataForChart(data, interval);
        if (!chart.current.candleSeries) return;
    
        chart.current.candleSeries.setData(priceData);
    
        if (priceLines.current && priceLines.current.length > 0) {
            priceLines.current.forEach(line => {
                chart.current.candleSeries.removePriceLine(line);
            });
            priceLines.current = [];
        }
    
        if (showAlerts && alerts && alerts.length > 0) {
            alerts.forEach(alert => {
                const level = parseFloat(alert.level);
                const alertTypeText = alert.alert_type === 'below' ? 'Baja' : 'Alza';
                const color = alert.alert_type === 'above' ? '#54aaff' : '#ff4800';
                const labelText = `Alerta: ${alertTypeText} ${alert.level}`;

                const priceLine = chart.current.candleSeries.createPriceLine({
                    price: level,
                    color: color,
                    lineWidth: 1,
                    lineStyle: 2,
                    axisLabelVisible: true,
                    title: labelText,
                });
                priceLines.current.push(priceLine);
            });
        }
    
        updateMASeries(showMA, priceData);
        if (chart.current) {
            chart.current.timeScale().fitContent();
        }
    }, [data, interval, showAlerts, alerts, showMA, formatDataForChart, updateMASeries]);

    // Estilos
    const switchBaseStyle = {
        margin: 0,
        color: 'white',
        fontSize: isSmallScreen ? '10px' : '14px',
        transition: 'opacity 0.2s',
        background: 'transparent',
        opacity: 0.8,
    };

    const intervalsContainerStyle = {
        position: 'absolute',
        top: '2%',
        left: '2%',
        display: 'flex',
        gap: '5px',
        zIndex: 10,
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'center'
    };

    const getIntervalButtonStyle = (intervalKey) => ({
        backgroundColor: hoveredInterval === intervalKey ? 'rgba(238,172,24,0.8)' : 'rgba(238,172,24,0.3)',
        color: 'black',
        border: 'none',
        borderRadius: '4px',
        fontSize: isSmallScreen ? '10px' : '14px',
        padding: isSmallScreen ? '2px 4px' : '4px 8px',
        transition: 'background-color 0.2s',
        cursor: 'pointer',
    });

    const buttonStyle = {
        position: 'absolute',
        bottom: '8%',   
        right: '4%',    
        backgroundColor: hovered ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.3)',
        color: 'black',
        border: 'none',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        fontSize: '20px',
        lineHeight: '30px',
        textAlign: 'center',
        padding: '0',
        transition: 'background-color 0.2s',
        zIndex: 10,
    };

    return (
        <div style={{ marginTop: '20px', textAlign: 'center', padding: '0 20px', position: 'relative' }}>
            <div
                ref={chartContainerRef}
                style={{ width: '100%', height: getChartHeight(), backgroundColor: '#222', position: 'relative' }}
            >
                <div style={intervalsContainerStyle}>
                    <button
                        onClick={() => setIntervalType(5)}
                        onMouseEnter={() => setHoveredInterval('5m')}
                        onMouseLeave={() => setHoveredInterval(null)}
                        style={getIntervalButtonStyle('5m')}
                    >
                        5m
                    </button>
                    <button
                        onClick={() => setIntervalType(15)}
                        onMouseEnter={() => setHoveredInterval('15m')}
                        onMouseLeave={() => setHoveredInterval(null)}
                        style={getIntervalButtonStyle('15m')}
                    >
                        15m
                    </button>
                    <button
                        onClick={() => setIntervalType(60)}
                        onMouseEnter={() => setHoveredInterval('1h')}
                        onMouseLeave={() => setHoveredInterval(null)}
                        style={getIntervalButtonStyle('1h')}
                    >
                        1h
                    </button>

                    <Switch
                        checked={showAlerts}
                        onChange={() => setShowAlerts(!showAlerts)}
                        label="Alertas"
                        style={switchBaseStyle}
                    />

                    <Switch
                        checked={showMA}
                        onChange={() => setShowMA(!showMA)}
                        label="MA (20)"
                        style={switchBaseStyle}
                    />
                </div>

                <button
                    onClick={() => chart.current.timeScale().scrollToRealTime()}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={buttonStyle}
                >
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default CandleStickChart;
