import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HistoricalDataTable from '../components/tables/HistoricalDataTable';
import MarketSummaryTable from '../components/tables/MarketSummaryTable';
import { Button, Radio, RadioGroup } from '@blueprintjs/core'; 
import { DateTime } from 'luxon';
import { GenericCell } from '../templates/tables/Cells';
import * as XLSX from 'xlsx';
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParametersHooks';
import * as con from '../GlobalConstants';

const calculateElementStyles = (width) => {
    if (width <= 1480) {
        return {
            fontSize: '12px',
            padding: '10px',
            margin: '10px',
            headerFontSize: '16px',
        };
    } else {
        return {
            fontSize: '16px',
            padding: '20px',
            margin: '20px',
            headerFontSize: '20px',
        };
    }
};

function HistoricalDataPage() {
    const historicalDollarInfo = useSelector((state) => state['store']['market_values']['historic_dollar_info']);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [elementStyles, setElementStyles] = useState(calculateElementStyles(window.innerWidth));
    const [selectedPage, setSelectedPage] = useState('historical'); 
    const isMobile = RefApplicationParameterHook(con.IS_MOBILE);

    useEffect(() => {
        const adjustElementStyles = () => {
            const width = window.innerWidth;
            setElementStyles(calculateElementStyles(width));
        };
        
        window.addEventListener('resize', adjustElementStyles);
        return () => window.removeEventListener('resize', adjustElementStyles);
    }, []);

    useEffect(() => {
        if (historicalDollarInfo && historicalDollarInfo.dates.length > 0) {
            const lastDate = DateTime.fromISO(historicalDollarInfo.dates[historicalDollarInfo.dates.length - 1]).toJSDate();
            const oneMonthBeforeLastDate = DateTime.fromJSDate(lastDate).minus({ months: 1 }).toJSDate();

            setStartDate(oneMonthBeforeLastDate);
            setEndDate(lastDate);
        }
    }, [historicalDollarInfo]);

    useEffect(() => {
        if (historicalDollarInfo) {
            const data = historicalDollarInfo.dates.map((date, index) => ({
                fecha: DateTime.fromISO(date),
                monto: historicalDollarInfo.volume[index],
                trm: historicalDollarInfo.trm[index],
                apertura: historicalDollarInfo.open[index],
                max: historicalDollarInfo.high[index],
                min: historicalDollarInfo.low[index],
                cierre: historicalDollarInfo.close[index],
            }));

            const filtered = data.filter(item => 
                (!startDate || item.fecha >= DateTime.fromJSDate(startDate)) &&
                (!endDate || item.fecha <= DateTime.fromJSDate(endDate))
            );

            setFilteredData(filtered.reverse());
        }
    }, [startDate, endDate, historicalDollarInfo]);

    const handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(filteredData.map(item => ({
            Fecha: item.fecha.toISODate(),
            Monto: item.monto,
            TRM: item.trm,
            Apertura: item.apertura,
            Máx: item.max,
            Mín: item.min,
            Cierre: item.cierre,
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Datos Filtrados");
        XLSX.writeFile(wb, "datos_filtrados.xlsx");
    };

    return (
        <div style={{ padding: elementStyles.padding, width: '100%' }}>
            {/* Selectores de Fecha */}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '35px' }}>
                <div>
                    <label style={{ fontSize: elementStyles.fontSize }}>Fecha Inicio:</label>
                    <GenericCell
                        cellType="date"
                        value={startDate ? DateTime.fromJSDate(startDate).toISODate() : ''}
                        setValue={(val) => setStartDate(DateTime.fromISO(val).toJSDate())}
                        editing={true}
                        style={{ fontSize: elementStyles.fontSize, padding: '10px', height: 'auto', width: 'auto' }}
                    />
                </div>
                <div>
                    <label style={{ fontSize: elementStyles.fontSize }}>Fecha Fin:</label>
                    <GenericCell
                        cellType="date"
                        value={endDate ? DateTime.fromJSDate(endDate).toISODate() : ''}
                        setValue={(val) => setEndDate(DateTime.fromISO(val).toJSDate())}
                        editing={true}
                        style={{ fontSize: elementStyles.fontSize, padding: '10px', height: 'auto', width: 'auto' }}
                    />
                </div>
            </div>

            {/* Selector de Página*/}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <RadioGroup
                    inline
                    selectedValue={selectedPage} 
                    onChange={(e) => setSelectedPage(e.currentTarget.value)}
                >
                    <Radio value="historical" label="Información Histórica" />
                    <Radio value="summary" label="Resumen Mercado" />
                </RadioGroup>
            </div>

            {/* Contenido Condicional */}
            {selectedPage === 'historical' && (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: elementStyles.margin, gap: '15px', marginTop: '20px' }}>
                        <h2 style={{ margin: 0, fontSize: elementStyles.headerFontSize }}>INFORMACIÓN HISTÓRICA DEL DÓLAR</h2>
                        {!isMobile && (
                            <Button 
                                icon="download" 
                                text="Descargar Excel" 
                                onClick={handleDownload} 
                                style={{ fontSize: elementStyles.fontSize }}
                            />
                        )}
                    </div>

                    <div style={{ marginTop: '40px' }}>
                        <HistoricalDataTable fixedSize={elementStyles.fontSize} data={filteredData} />
                    </div>
                </div>
            )}

            {selectedPage === 'summary' && (
                <div style={{ marginTop: '40px' }}>
                    <h2 style={{ textAlign: 'center', fontSize: elementStyles.headerFontSize }}>RESUMEN MERCADO</h2>
                    <MarketSummaryTable fixedSize={elementStyles.fontSize} filteredData={filteredData} />
                </div>
            )}
        </div>
    );
}

export default HistoricalDataPage;
