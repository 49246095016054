// src/components/scenarios/Scenarios.js

import React, { useEffect, useState } from 'react'
import { TITLE, X_LABEL, Y_LABEL, X_VALUES, Y_VALUES, LABEL_GENERATING_FUNCTION } from '../../templates/charts/ChartConstants'
import * as cc from '../../templates/charts/ChartConstants'  // Importamos cc
import { MultiLineChartHook } from '../../templates/charts/hooks/MultiLineChartHook'
import * as con from "../../GlobalConstants"
import { addDays, addMonths, daysBetweenDates } from '../../utils/dateFunctions'
import { RefApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { formatMoney } from '../../utils/moneyFunctions'
import { ScenariosHook } from '../hooks/ScenariosHook'

function Scenarios({ selectedScenarios, chartParameters }) {

    // Parámetros del modelo
    const spot = RefSPOT()
    const termInMonths = RefApplicationParameterHook(con.TERM_IN_MONTHS)
    const marketVolatility = RefModelParameterHook(con.MARKET_VOLATILITY)

    // Escenarios
    const scenarios = ScenariosHook()

    // Parámetros de la aplicación
    const historicPricesAndDates = RefMarketValueHook(con.HISTORIC_DOLLAR_INFO)
    const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)
    const isMobile = RefApplicationParameterHook(con.IS_MOBILE)

    // Preparar parámetros del gráfico
    const chartParams = {
        [TITLE]: isMobile ? "ESCENARIOS" : "COMPARACIÓN ESCENARIOS EN LA TASA DE CAMBIO",
        [Y_LABEL]: isMobile ? "" : "Tasa de Cambio",
        [X_LABEL]: isMobile ? "" : "Fecha",
        ...chartParameters
    }

    // Ajustar tamaños de fuente y visibilidad según el estado móvil
    if (isMobile) {
        chartParams['titleFontSize'] = '12px' // Ajustar tamaño del título
        chartParams['axisFontSize'] = '10px'  // Ajustar tamaño de los ejes
        chartParams['showLabels'] = false     // No mostrar labels en los puntos
        chartParams['showXAxisLabels'] = false // No mostrar etiquetas en el eje X
        chartParams[cc.INCLUDE_LEGEND] = false // No incluir la leyenda en móvil
    }

    // Hook del gráfico multilinea
    const [chartData, setChartData] = useState([])
    const [objectReference, chart] = MultiLineChartHook({ parameters: chartParams })

    useEffect(() => {

        if (!chart.initialized)
            chart.initialize()

        const finalDate = addMonths(currentDate, termInMonths)
        const numPoints = daysBetweenDates(currentDate, finalDate)
        const dates = [...Array(numPoints).keys()].map((i) => addDays(currentDate, i))

        // Valores históricos
        const historicLength = historicPricesAndDates[con.DATES].length
        const historicDates = historicPricesAndDates[con.DATES].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([currentDate])
        const historicPrices = historicPricesAndDates[con.TRM].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([spot])

        // Agregar escenario histórico
        let data = [{
            name: con.HISTORIC_PRICES_NAME,
            [X_VALUES]: historicDates,
            [Y_VALUES]: historicPrices,
            [LABEL_GENERATING_FUNCTION]: isMobile ? null : (selObj, j) => `${selObj[X_VALUES][j].toLocaleDateString()}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)}`
        }]

        data = data.concat(Object.values(scenarios)
            .filter((ob) => selectedScenarios == null || selectedScenarios.includes(ob[con.ID]))
            .map((ob) => ({
                name: ob.name,
                [X_VALUES]: dates,
                [Y_VALUES]: dates.map((_, i) => ob.gen_fun(spot, i)),
                [LABEL_GENERATING_FUNCTION]: isMobile ? null : (selObj, j) => `${selObj[X_VALUES][j].toLocaleDateString()}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)}`
            }))
        )

        // Verificar si hubo cambios estructurales
        if (data.length !== chartData.length) {
            chart.initialize()
            chart.build(data)
        } else {
            chart.updateData(data)
            chart.updateLegend(data)
        }

        setChartData(data)

    }, [historicPricesAndDates, currentDate, scenarios, spot, termInMonths, marketVolatility, chart, chartData.length, selectedScenarios, isMobile])

    return (
        <svg ref={objectReference} />
    )
}

Scenarios.defaultProps = {
    selectedScenarios: null,
    chartParameters: {}
};

export default Scenarios
