// Component for All parameters
// TODO - Documentar
import React, { useState } from 'react';
import { Button } from '@blueprintjs/core'

// Style
import '../ComponentsGlobalStyle.css'
import { IsStaff, RefRole, RefUser } from '../hooks/AuthenticationHook';

// Global constants
import * as con from '../../GlobalConstants'

import { AverageForPercentage, CarryOverCoverage, NonZeroRowsCheckBox, PassThrough, ScenariosSelect, VisualizationPeriodicity } from './SingleParameters';
import Modal from '../../templates/popups/Modal';


export const CurrentPositionParameters = () => {

  const isStaff = IsStaff()
  const user = RefUser()
  const role = RefRole()
  const [showModal, setShowModal] = useState(false);
  const isNotViewer = role !== con.VIEWER
  const handleModal = () => setShowModal(!showModal);


    return (
    <div>
      {showModal && (
        <LegendModal show={showModal} handleModal={handleModal} />
      )}
        <div className='mediumParameterBox' >                                      
            {isNotViewer && <AverageForPercentage/>}
            {isNotViewer && <PassThrough/>}
            { isStaff && user[con.USERNAME] !== "carlos" ? <CarryOverCoverage /> : <></>}
            <NonZeroRowsCheckBox />                      
            <VisualizationPeriodicity/>              
        </div>
        <div className="gridBox" style={{ paddingLeft : '15px', paddingRight : '15px'}}>
          <div style={{paddingLeft : "0%", paddingTop : '20px'}}>  
            <Button icon="info-sign" text="Información" onClick={handleModal}/>
          </div> 
          <div style={{paddingLeft : "43%"}}>  
            <ScenariosSelect />   
          </div>        
            {/* <div style={{paddingLeft : "17%"}}>  
              <BandSelect/>
            </div> */}
        </div>
      </div>
    )
}



export const CurrentPositionParametersMobile = () => {


    return (
    <div>
        <div className='columnParameterBox' >                                                
            <AverageForPercentage />
            <ScenariosSelect />
            {/* <BandSelect/>              */}
        </div>
      </div>
    )
}


function LegendModal({ show, handleModal }) {

  return (
    <div>
      <Modal show={show} doAccept={null} showCancel={false} doCancel={handleModal} widthPercentage={65}>
        <h2>Leyendas</h2>

        <div>
          <ul style={{ textAlign: 'left' }}>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Fecha:</span> Fecha de cierre del periodo</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Exp. (Miles):</span> Suma la expociones en miles</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Cob. (Miles):</span> Es la suma de todos los instrumentos de cobertura, no incluyen derivados contrarios</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Tasa (Cobertura T.):</span> Es la tasa promedio de las coberturas, SI incluyen derivados contrarios</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Cob. Fwd:</span> Es la suma de todos los contratos Forwards para cubrir la posición del cliente</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Tasa Fwd:</span> Es la tasa promedio ponderada de los contratos Forwards para cubrir la posición del cliente</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Cob. Opc:</span> Es la suma de todas las opciones que cubren la posición del cliente</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Tasa Opc:</span> Es la tasa promedio ponderada de las opciones diseñadas para cubrir la posición del cliente</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>Cob. Contrarias:</span> Es la suma de los instrumentos que no van en línea con la posición del cliente</li>
            <li><span style={{ color: 'red', fontWeight:'bold' }}>% Cob.:</span> Es el porcentaje cubierto a partir de la suma de las (coberturas - Cob contrarias) / Exposición</li>
          </ul>
        </div>

        <div>
          <Button icon="cross" text="Cerrar" onClick={handleModal} />
        </div>
      </Modal>
    </div>
  );
}