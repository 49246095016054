// Generic Chart class
import * as cc from "../ChartConstants";
import * as d3 from "d3";
import { GenericChart } from "./GenericChart";


const DEFAULT_PARAMETERS = { // Legend
                            [cc.INCLUDE_LEGEND] : true,
                            [cc.LEGEND_HEIGHT] : 0.15, // Percentage
                            [cc.LEGEND_MARGIN_FROM_CHART] : 0.14, // Percentage
                            [cc.LEGEND_SELECT_SHIFT] : 30,                                          
                            [cc.LEGEND_NUM_ROWS] : 3,
                            [cc.LEGEND_CIRCLE_RADIUS] : 5,
                            [cc.LEGEND_PADDING]: 2,
                            [cc.ENFASIS_WEIGHT] : 900,
                            [cc.NORMAL_WEIGHT] : 20,
                            [cc.LEGEND_MARGIN_LEFT] : 5,
                            [cc.LEGEND_MARGIN_RIGHT] : 70,
                            [cc.INTERACTIVE_LEGEND] : true}

export class GenericChartWithLegend extends GenericChart{

    // General constructor
    constructor(parameters, objectReference){

        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)

        // Checks if legend is included
        this.legend_height = 0
        this.legend_margin_from_chart =  0
        if(this.parameters[cc.INCLUDE_LEGEND])
        {
            this.legend_height = this.parameters[cc.LEGEND_HEIGHT]* this.parameters[cc.PROPORTIONAL_HEIGHT];
            this.legend_margin_from_chart =  this.parameters[cc.LEGEND_MARGIN_FROM_CHART]* this.parameters[cc.PROPORTIONAL_HEIGHT];
        }

        // Overrides height
        this.height =  this.svgHeight  - this.parameters[cc.MARGIN][cc.TOP] - this.parameters[cc.MARGIN][cc.BOTTOM] - this.legend_height - this.legend_margin_from_chart;
        this.chart_middle_height = this.height/2 + this.parameters[cc.MARGIN][cc.TOP] 

        // Legend variables    
        this.numLegendLabels = null
        this.selectedLegend = null
        this.numPerRow = null
        this.legendXScale = null
        this.legendYScale = null
        this.legendColorScheme = null

    }

    constructLegend(legendData)
    {
        this.mainComponent.selectAll(".legendElement").remove()

        // Updates the Color pallette
        this.legendColorScheme = this.getColorScheme(legendData.length)

        // Constructs Legend Elements
        this.numPerRow = Math.ceil(legendData.length/this.parameters[cc.LEGEND_NUM_ROWS])
        this.legendXScale = d3.scaleLinear().domain([0, this.numPerRow]).range([this.parameters[cc.LEGEND_MARGIN_LEFT], this.svgWidth - this.parameters[cc.LEGEND_MARGIN_RIGHT]])
        this.legendYScale = d3.scaleLinear().domain([0, this.parameters[cc.LEGEND_NUM_ROWS]]).range([this.legend_margin_from_chart + this.height + this.parameters[cc.MARGIN][cc.TOP], this.legend_margin_from_chart  + this.legend_height + this.height + this.parameters[cc.MARGIN][cc.TOP]])
        
        this.numLegendLabels = legendData.length
        this.selectedLegend = -1

        legendData.forEach((ob, i) => {
            
            // Legend                        
            // Circles
            this.mainComponent.append('circle')
                        .attr("class", "legendElement overlay")
                        .attr('id', `legend-circle-${i}`)
                        .attr('cx', this.getLegendCiclePosition(i))
                        .attr('cy', this.legendYScale( Math.floor( i / this.numPerRow)) -  this.parameters[cc.LEGEND_CIRCLE_RADIUS]/2 - this.parameters[cc.LEGEND_PADDING])
                        .text(ob.name)
                        .style("fill", this.legendColorScheme(i))
                        .attr("r", this.parameters[cc.LEGEND_CIRCLE_RADIUS])
                        .on("click", (event) => this.selectSingleLegendLabel(event, i))
                        //.on("mouseover", () => {d3.select(this).style("cursor", "pointer")})
            // Text
            this.mainComponent.append('text')
                            .attr("class", "legendElement overlay")
                            .attr('id', `legend-${i}`)
                            .attr('x', this.getLegendTextPoisition(i))
                            .attr('y', this.legendYScale( Math.floor( i / this.numPerRow)))
                            .text(ob.name)
                            .style("fill", this.legendColorScheme(i))
                            .style("alignment-baseline", "middle")
                            .on("click", (event) => this.selectSingleLegendLabel(event, i))
                            //.on("mouseover", () => {d3.select(this).style("cursor", "pointer")})
                            .attr('font-weight', this.parameters[cc.NORMAL_WEIGHT])
        })

    }

    build(initialData) {
        super.build(initialData)
        if (this.parameters[cc.INCLUDE_LEGEND]) {
            this.constructLegend(initialData)
        }
    }

    updateData(newData)
    {
        super.updateData(newData)   
    }

    updateLegend(newData) {
        if (this.parameters[cc.INCLUDE_LEGEND]) {
            this.constructLegend(newData)
        }
    }



    selectSingleLegendLabel(event, i)
    {

        if(! this.parameters[[cc.INTERACTIVE_LEGEND]])
            return
            
        this.selectedLegend = i

        for (let j = 0; j < this.numLegendLabels; j++)
        {
            if(i === j)
                continue

            this.svgEl.select(`#legend-${j}`).transition()
                                    .duration(this.parameters[cc.ANIMATION_TIME])
                                    .attr("font-weight", this.parameters[cc.NORMAL_WEIGHT])
                                    .attr("x", this.getLegendTextPoisition(j))
                                    .style("opacity", this.parameters[cc.HIDDEN_OPACITY]);

            this.svgEl.select(`#legend-circle-${j}`).transition()
                                            .duration(this.parameters[cc.ANIMATION_TIME])
                                            .attr("cx", this.getLegendCiclePosition(j))
                                            .style("opacity", this.parameters[cc.HIDDEN_OPACITY]);
        }

        this.svgEl.select(`#legend-${i}`).transition()
        .duration(this.parameters[cc.ANIMATION_TIME])
        .attr("font-weight", this.parameters[cc.ENFASIS_WEIGHT])
        .attr("x", this.getLegendTextPoisition(i, true))
        .style("opacity", this.parameters[cc.FULL_OPACITY]); 

        this.svgEl.select(`#legend-circle-${i}`).transition()
                    .duration(this.parameters[cc.ANIMATION_TIME])
                    .attr("cx", this.getLegendCiclePosition(i, true))
                    .style("opacity", this.parameters[cc.FULL_OPACITY]); 

    }

    onMouseClickEmpty(self,event)
    {
        super.onMouseClickEmpty(self,event)

        if(this.selectedLegend !== -1)
        {
            
            for (let i = 0; i < this.numLegendLabels; i++)
            {
                this.svgEl.select(`#line-${i}`).transition()
                                        .duration(this.parameters[cc.ANIMATION_TIME])
                                        .attr('stroke-width',this.parameters[cc.NORMAL_WIDTH])
                                        .style("opacity", this.parameters[cc.FULL_OPACITY]);

                                        this.svgEl.select(`#legend-${i}`).transition()
                                        .duration(this.parameters[cc.ANIMATION_TIME])
                                        .attr("font-weight", this.parameters[cc.NORMAL_WEIGHT])
                                        .attr("x", this.getLegendTextPoisition(i))
                                        .style("opacity", this.parameters[cc.FULL_OPACITY]);
                
                                        this.svgEl.select(`#legend-circle-${i}`).transition()
                                                .duration(this.parameters[cc.ANIMATION_TIME])
                                                .attr("cx", this.getLegendCiclePosition(i))
                                                .style("opacity", this.parameters[cc.FULL_OPACITY]);

            }

        }

        this.selectedLegend = -1


    }


    // Support Functions
    // --------------------------
    getLegendTextPoisition = (i,selected) =>
    {
        
        let val = this.parameters[cc.LEGEND_CIRCLE_RADIUS] + this.parameters[cc.LEGEND_PADDING] + this.legendXScale( i % this.numPerRow)
        if(selected === true)
            val -= this.parameters[cc.LEGEND_SELECT_SHIFT]
        
        return(val)
    }

    getLegendCiclePosition = (i, selected) =>
    {
        let val = this.legendXScale( i % this.numPerRow)
        if(selected === true)
            val -= this.parameters[cc.LEGEND_SELECT_SHIFT]
        
        return(val)
    }
  
    

}