// src/templates/charts/classes/MultiLineChart.js

import * as d3 from "d3";
import "../Charts.css"
import * as cc from '../ChartConstants';
import { GenericChartWithLegend } from "./GenericChartWithLegend";

const DEFAULT_PARAMETERS = {
    [cc.NORMAL_WIDTH]: 2.5,
    [cc.ENFASIS_WIDTH]: 7.5,
    'titleFontSize': '16px',
    'axisFontSize': '12px',
    'showLabels': true,
    'showXAxisLabels': true,
}

export class MultiLineChart extends GenericChartWithLegend {

    constructor(parameters, objectReference) {
        super({ ...DEFAULT_PARAMETERS, ...parameters }, objectReference)

        // Adjust global parameters
        this.selectedData = null;
        this.selectedFlowId = -1;
        this.num_lines = 0
    }

    // Overwrite
    // -------------------------
    build(initialData) {
        super.build(initialData)
        super.updateData(initialData)

        // For accessing inside D3
        let self = this;

        this.num_lines = initialData.length;

        // Updates the Color palette
        this.colorScheme = this.getColorScheme(this.num_lines)

        // Creates the scales
        this.xScale = d3.scaleTime()
            .domain([d3.min(initialData.map(ob => d3.min(ob[cc.X_VALUES]))), d3.max(initialData.map(ob => d3.max(ob[cc.X_VALUES])))])
            .range([0, this.width])

        this.min_left_y = d3.min(initialData.map(ob => d3.min(ob[cc.Y_VALUES])))
        this.max_left_y = d3.max(initialData.map(ob => d3.max(ob[cc.Y_VALUES])))
        this.final_left_padding = (this.max_left_y - this.min_left_y) * this.parameters[cc.Y_PADDING]
        this.yScale = d3.scaleLinear()
            .domain([this.min_left_y - this.final_left_padding, this.max_left_y + this.final_left_padding])
            .range([this.height, 0])

        // Sets Axis
        const xAxisGenerator = d3.axisBottom(this.xScale)
        if (!this.parameters['showXAxisLabels']) {
            xAxisGenerator.tickFormat('')
        }
        const xAxis = this.mainComponent.append('g')
            .attr('id', 'xAxis')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + this.height + ')')
            .call(xAxisGenerator)
        xAxis.selectAll("text")
            .style("font-size", this.parameters['axisFontSize'])

        const yAxisGenerator = this.parameters[cc.Y_AXIS_ON_LEFT] ? d3.axisLeft(this.yScale) : d3.axisRight(this.yScale)
        const yAxis = this.mainComponent.append('g')
            .attr('id', 'yAxis')
            .attr('class', 'axis axis--y')
            .attr("transform", this.parameters[cc.Y_AXIS_ON_LEFT] ? null : `translate(${this.width},0)`)
            .call(yAxisGenerator)
        yAxis.selectAll("text")
            .style("font-size", this.parameters['axisFontSize'])

        // Adjust title font size
        if (this.parameters['titleFontSize']) {
            this.svgEl.select('.chart-title').style('font-size', this.parameters['titleFontSize']);
        }

        // Deletes all paths
        this.mainComponent.selectAll(".constructedElement").remove()

        initialData.forEach((ob, i) => {

            // Lines
            this.mainComponent.append("path")
                .datum(ob[cc.X_VALUES])
                .attr('id', `line-${i}`)
                .attr('class', 'line constructedElement')
                .attr("fill", "none")
                .attr("stroke", this.colorScheme(i))
                .attr("stroke-width", this.parameters[cc.NORMAL_WIDTH])
                .attr("d", d3.line()
                    .x(function (d, i) { return self.xScale(ob[cc.X_VALUES][i]) })
                    .y(function (d, i) { return self.yScale(ob[cc.Y_VALUES][i]) })
                )
                .on("mouseover", function (event) {
                    d3.select(this).style("cursor", "pointer");
                    d3.select(this)
                        .transition()
                        .duration(self.parameters[cc.ANIMATION_TIME])
                        .attr('stroke-width', self.parameters[cc.ENFASIS_WIDTH]);
                }).on("mouseout", function (event) {

                    if (self.selectedFlowId !== i) {
                        d3.select(this).transition()
                            .duration(self.parameters[cc.ANIMATION_TIME])
                            .attr('stroke-width', self.parameters[cc.NORMAL_WIDTH]);
                    }

                })
                .on("click", (event) => this.selectSingleLegendLabel(event, i))

        })

        // Declares focusElement
        this.focusElement = this.mainComponent.append("g")
            .attr("class", "focusHidden constructedElement")

        if (this.parameters['showLabels']) {

            this.focusElement.append("circle")
                .attr("r", 4.5)
                .attr("fill", "var(--text-color)");

            // Label background
            this.focusElement.append("rect")
                .attr("fill", "var(--background-color-2)")
                .attr("height", "2em")
                .attr("width", "12em")
                .attr("y", "-3em")
                .attr("x", "-5em")

            this.focusElement.append("text")
                .attr("y", "-1.5em")
                .attr("x", "-5em")
                .attr("fill", "var(--text-color-highlighted)");

            // Add dotted lines
            this.focusElement.append('line')
                .attr('id', 'hLine')
                .attr("stroke", "var(--text-color)")
                .attr("stroke-width", this.parameters[cc.NORMAL_WIDTH] / 3)
                .style("stroke-dasharray", ("3, 3"))

            this.focusElement.append('line')
                .attr('id', 'vLine')
                .attr("stroke", "var(--text-color)")
                .attr("stroke-width", this.parameters[cc.NORMAL_WIDTH] / 3)
                .style("stroke-dasharray", ("3, 3"))

        }

    }

    // Function that updates the chart
    updateData(newData) {

        super.updateData(newData)

        // For accessing inside D3
        let self = this;

        // Updates the scales
        this.xScale.domain([d3.min(newData.map(ob => d3.min(ob[cc.X_VALUES]))), d3.max(newData.map(ob => d3.max(ob[cc.X_VALUES])))])
        this.yScale.domain([d3.min(newData.map(ob => d3.min(ob[cc.Y_VALUES]))) - this.final_left_padding, d3.max(newData.map(ob => d3.max(ob[cc.Y_VALUES]))) + this.final_left_padding])

        // Updates Axis
        const yAxisGenerator = this.parameters[cc.Y_AXIS_ON_LEFT] ? d3.axisLeft(this.yScale) : d3.axisRight(this.yScale)
        this.mainComponent.select("#yAxis")
            .transition()
            .duration(this.parameters[cc.ANIMATION_TIME])
            .call(yAxisGenerator)
            .selectAll("text")
            .style("font-size", this.parameters['axisFontSize']);

        const xAxisGenerator = d3.axisBottom(this.xScale)
        if (!this.parameters['showXAxisLabels']) {
            xAxisGenerator.tickFormat('')
        }
        this.mainComponent.select("#xAxis")
            .transition()
            .duration(this.parameters[cc.ANIMATION_TIME])
            .call(xAxisGenerator)
            .selectAll("text")
            .style("font-size", this.parameters['axisFontSize']);

        // Updates the line values
        newData.forEach((ob, i) => {

            // Lines
            this.mainComponent.select(`#line-${i}`)
                .datum(ob[cc.X_VALUES])
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .attr("d", d3.line()
                    .x(function (d, i) { return self.xScale(ob[cc.X_VALUES][i]) })
                    .y(function (d, i) { return self.yScale(ob[cc.Y_VALUES][i]) })
                )

        })

    }

    // Support Functions
    // --------------------------

    onMouseMove(self, event) {

        if (self.selectedData !== null && self.parameters['showLabels']) {
            let x0 = self.xScale.invert(d3.pointer(event)[0])
            let i = d3.bisectLeft(self.selectedData[cc.X_VALUES], x0)

            if (i < self.selectedData[cc.X_VALUES].length) {
                let x = self.xScale(self.selectedData[cc.X_VALUES][i])
                let y = self.yScale(self.selectedData[cc.Y_VALUES][i])

                let text = self.selectedData[cc.LABEL_GENERATING_FUNCTION] === undefined ? self.selectedData[cc.Y_VALUES][i] : self.selectedData[cc.LABEL_GENERATING_FUNCTION](self.selectedData, i)

                self.focusElement.attr("transform", "translate(" + x + "," + y + ")");
                self.focusElement.select("text").text(text)
                    .attr("x", `-${0.25 * text.length}em`);

                self.focusElement.select("rect").attr("x", `-${0.28 * text.length}em`)
                    .attr("width", `${0.55 * text.length}em`);

                // Lines
                self.focusElement.select("#hLine")
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', -x)
                    .attr('y2', 0)
                self.focusElement.select("#vLine")
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', self.height - y)
            }

        }

    }

    onMouseClickEmpty(self, event) {

        super.onMouseClickEmpty(self, event)

        if (self.selectedFlowId !== -1) {

            for (let i = 0; i < self.num_lines; i++) {

                self.svgEl.select(`#legend-circle-${i}`).transition()
                    .duration(self.parameters[cc.ANIMATION_TIME])
                    .attr("cx", self.getLegendCiclePosition(i))
                    .style("opacity", self.parameters[cc.FULL_OPACITY]);

            }

        }

        // Adjust global parameters
        self.selectedData = null;
        self.selectedFlowId = -1;

        // Adjust focusElement
        if (self.parameters['showLabels']) {
            self.focusElement.classed('focusHidden', true);
            self.focusElement.classed('focusShow', false);
        }
    }

    selectLine(i) {

        let ob = this.data[i];

        for (let j = 0; j < this.num_lines; j++) {
            if (i === j)
                continue

            this.svgEl.select(`#line-${j}`).transition()
                .duration(this.parameters[cc.ANIMATION_TIME])
                .attr('stroke-width', this.parameters[cc.NORMAL_WIDTH])
                .style("opacity", this.parameters[cc.HIDDEN_OPACITY]);

        }

        this.svgEl.select(`#line-${i}`).transition()
            .duration(this.parameters[cc.ANIMATION_TIME])
            .attr('stroke-width', this.parameters[cc.ENFASIS_WIDTH])
            .style("opacity", this.parameters[cc.FULL_OPACITY]);

        this.selectedData = ob;
        this.selectedFlowId = i;
        if (this.parameters['showLabels']) {
            this.focusElement.classed('focusHidden', false);
            this.focusElement.classed('focusShow', true);
        }

    }

    // Override Functions
    // -----------------------
    selectSingleLegendLabel(event, i) {
        super.selectSingleLegendLabel(event, i);
        this.selectLine(i);
        this.onMouseMove(this, event)
    }
}
