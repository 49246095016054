// TODO - Documentar
import React from 'react';
import { Alignment,Button,Menu,Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Popover, Position } from '@blueprintjs/core';

// Global constants
import * as con from "../../GlobalConstants"
import { formatDateForUser } from '../../utils/dateFunctions';
import { translateParameter } from '../../utils/translateFunctions';
import SimpleSpot from '../spot/SimpleSpot';
import Clock from 'react-live-clock';


import "./Nav.css"

import { CustomActionNavButton } from './CustomNavButton';
import { userLogOut } from '../../store/actions/authentication';
import { AllModelParameterHook } from '../hooks/ModelParameterHooks';
import { RefSetCurrentPage } from '../hooks/NavegationHook';
import { RefUserAttributesHook } from '../hooks/UserAttributesHook';
import { formatAccordingToType} from '../../templates/Types'
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook';
import { UserLinesHook } from '../hooks/UserLinesHook';
import CustomLineSelect from './CustomLineSelect';
import NumericVisualizer from './NumericVisualizer';
import CustomNavbarDivider from './CustomNavbarDivider';
import { buildCollapsedNavigationElement } from './Nav';

function MobileNav() {

    
    // Model Parameters
    // -----------------
    const modelParameters = AllModelParameterHook()



    
    // Lines 
    const userLines = UserLinesHook()

    // User Avatar
    const avatarImage = RefUserAttributesHook(con.AVATAR_IMAGE)



      
    // Transaction Summary
    // eslint-disable-next-line
    const [summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook({onlySummary : true})

    // Navigation
    const navigate = RefSetCurrentPage();

      

  return(              
    <div>      
        <Navbar fixedToTop={true} className="customNavBar noCarret" style={{height : `${con.MOBILE_NAV_BAR}vh`}}>            
            <div className="topContainer">                          
                <NavbarGroup align={Alignment.LEFT} className="customNavBarGroup">
                  <div style={{maxWidth : "30vw"}}>                                         
                  <Popover content={<Menu >                                      
                                      {
                                        con.NAVIGATION_PAGES_ARRAY.filter(elem => 
                                          elem[con.NAV_LEVEL] !== con.STAFF && elem[con.NAV_INCLUDE_MOBILE] === true).map((el,i) =>
                                          {
                                            if(el[con.NAV_TYPE] === con.NAV_MULTIPLE)
                                            {
                                              el[con.NAV_MENU_TEXTS] = el[con.NAV_MENU_TEXTS].filter((_, j) => el[con.NAV_INCLUDE_PAGES_MOBILE][j])
                                              el[con.NAV_MENU_PAGES_ID] = el[con.NAV_MENU_PAGES_ID].filter((_, j) => el[con.NAV_INCLUDE_PAGES_MOBILE][j]) 

                                            }

                                            return(buildCollapsedNavigationElement(el,i, navigate))
                                          })
                                                                                
                                      }
                                      </Menu>} position={Position.RIGHT_TOP}>
                                  <Button icon={"menu"} 
                                          className={`bp3-minimal customNavButton`} />
                                  </Popover>                                                                                                                      
                </div> 
                <NavbarDivider />               
                </NavbarGroup>            
                <NavbarGroup align={Alignment.RIGHT} className="customNavBarGroup" >
                    <CustomNavbarDivider /> 

                    { avatarImage != null ? <img src={avatarImage} alt="Logo" width="auto" style={{cursor:"pointer", marginLeft : "2px", marginRight : "2px"}} height={con.NAV_IMAGE_HEIGHT} onClick={() => navigate(con.PAGE_USER_PROFILE)}/>
                                           : <CustomActionNavButton icon="person" onClick={() => navigate(con.PAGE_USER_PROFILE)}/>
                     } 
                     <CustomNavbarDivider />
                     {
                       userLines.length === 0 ? <NavbarHeading style={{marginLeft : "1vh", fontSize : "1vw"}}> Cargando Lineas </NavbarHeading>
                                                              : <CustomLineSelect /> 
                                                              
                     }                               
                    <CustomNavbarDivider /> 
                    <CustomActionNavButton icon="power"  text="" onClick={() => userLogOut()} title="Salir"/>                
                </NavbarGroup>
            </div>
            <div style={{height : "0.2vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup">      
              <div className="parameters">
                <p><strong>Fecha: </strong> {formatDateForUser(modelParameters[con.CURRENT_DATE])}</p>
                <p><strong>Hora: </strong> <Clock format={'HH:mm:ss'} ticking={true} timezone={'America/Bogota'} /></p>
                <SimpleSpot />
              </div>                                
            </NavbarGroup>    
            <div style={{height : "0.1vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup"> 
              <div className="parameters">  
                <p><strong>Cliente: </strong> {translateParameter(modelParameters[con.CLIENT_TYPE])}</p>
                <p><strong>Periodicidad: </strong> {translateParameter(modelParameters[con.PERIODICITY])}</p>
                <p><strong>{"Plazo (mes): "}</strong> {modelParameters[con.TERM_PERIODICITY]}</p>
              </div>
            </NavbarGroup>    
            <div style={{height : "0.1vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup">   
              <div className="parameters">  
                <NumericVisualizer title={"Exp:"} value={hasSummaryTable ?  summaryRow[con.EXPOSURE_AMOUNT] : 0} formatValue={value => formatAccordingToType(con.MONEY_THOUSANDS, value, {[con.CURRENCY]: con.MONEY_USD})}/>                
                <NumericVisualizer title={"Cob:"} value={hasSummaryTable ?  summaryRow[con.COVERAGE_AMOUNT] : 0} formatValue={value => formatAccordingToType(con.MONEY_THOUSANDS, value, {[con.CURRENCY]: con.MONEY_USD})} inverse={true}/>
                <NumericVisualizer title={"% Cob:"} value={hasSummaryTable ? summaryRow[con.COVERAGE_PERCENTAGE]: 1} formatValue={value => formatAccordingToType(con.PERCENTAGE, value)} inverse={true}/>              
              </div>
            </NavbarGroup>  
            <div style={{height : "0.1vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup">      
              <div className="parameters">                
                <p><strong>Pos. Banda: </strong>{ hasSummaryTable  ? summaryRow[con.POSITION_IN_BANDS]: ""}</p>
                <NumericVisualizer title={"VaR Op:"} value={hasSummaryTable ?  summaryRow[con.VAR] : 0} formatValue={value => formatAccordingToType(con.MONEY_MILLIONS, value, {[con.CURRENCY]: con.MONEY_COP})}/>
              </div>                                
            </NavbarGroup>   
            <div style={{height : "0.4vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>

        </Navbar>
      </div>  )

    
}

export default MobileNav;
