import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { useSelector } from 'react-redux';
import MarketSummaryRow from './MarketSummaryRow';
import { formatAccordingToType } from '../../templates/Types';  
import * as con from '../../GlobalConstants';  

const calculateStyles = (width) => {
    if (width <= 768) {
        return {
            fontSize: '9px',
            paddingCell: '4px 6px',
            minWidth: '5px',
        };
    } else if (width <= 1480) {
        return {
            fontSize: '15px',
            paddingCell: '3px 5px',
            minWidth: '80px',
        };
    } else {
        return {
            fontSize: '19px',
            paddingCell: '5px 11px',
            minWidth: '110px',
        };
    }
};

function MarketSummaryTable({ fixedSize, filteredData }) {
    const marketSummaries = useSelector((state) => state['store']['market_values']['market_summaries']);
    const [tableStyles, setTableStyles] = useState(calculateStyles(window.innerWidth));

    useEffect(() => {
        const adjustStyles = () => {
            setTableStyles(calculateStyles(window.innerWidth));
        };

        window.addEventListener('resize', adjustStyles);
        adjustStyles(); 
        return () => window.removeEventListener('resize', adjustStyles);
    }, []);

    if (!marketSummaries) {
        return <div>No hay datos disponibles</div>;
    }

    const summaryData = Object.keys(marketSummaries).map((period, index) => ({
        rowId: index,
        periodo: period,
        volatilidad: marketSummaries[period]['volatility'],
        tasaPromedio: formatAccordingToType(con.MONEY, marketSummaries[period]['average'], { allowDecimals: true }), 
        volPromedio: marketSummaries[period]['vol_promedio'].toFixed(2),
        max: formatAccordingToType(con.MONEY, marketSummaries[period]['max'], { allowDecimals: true }), 
        min: formatAccordingToType(con.MONEY, marketSummaries[period]['min'], { allowDecimals: true }), 
    }));

    const calculateFiltroSummary = (data) => {
        if (data.length === 0) return { volatilidad: 0, tasaPromedio: 0 };
    
        const closePrices = data.map(item => item.cierre);
    
        const tasaPromedio = closePrices.reduce((acc, curr) => acc + curr, 0) / closePrices.length;
    
        const returns = closePrices.slice(1).map((price, i) => price / closePrices[i] - 1);
        const meanReturn = returns.reduce((acc, curr) => acc + curr, 0) / returns.length;
        const variance = returns.reduce((acc, curr) => acc + Math.pow(curr - meanReturn, 2), 0) / (returns.length - 1);
        const volatility = Math.sqrt(variance) * Math.sqrt(252); 
    
        return { volatilidad: volatility, tasaPromedio };
    };

    const filtroSummary = calculateFiltroSummary(filteredData);

    summaryData.push({
        rowId: summaryData.length,
        periodo: 'Filtro',
        volatilidad: filtroSummary.volatilidad,
        tasaPromedio: formatAccordingToType(con.MONEY, filtroSummary.tasaPromedio, { allowDecimals: true }), 
        volPromedio: null,
        max: null,
        min: null,
    });

    return (
        <div className="contenedor-tabla" style={{ fontSize: tableStyles.fontSize }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row">
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>PERIODO</div>
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>VOLATILIDAD</div>
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>TASA PROMEDIO</div>
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>VOL PROMEDIO</div>
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>MÁX</div>
                    <div className="flex-celda" style={{ padding: tableStyles.paddingCell, textAlign: 'center', minWidth: tableStyles.minWidth }}>MÍN</div>
                </div>
            </div>

            <div className="flex-tabla">
                {summaryData.map((data, index) => (
                    <MarketSummaryRow 
                        key={index} 
                        rowId={index} 
                        data={data} 
                        padding={tableStyles.paddingCell}  
                        minWidth={tableStyles.minWidth}  
                    />
                ))}
            </div>
        </div>
    );
}

MarketSummaryTable.defaultProps = {
    fixedSize: null,
    filteredData: [],
};

export default MarketSummaryTable;
