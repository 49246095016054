import { Button } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import Modal from '../../templates/popups/Modal'
import * as con from "../../GlobalConstants"
import { Account, CoverageFWD, CoverageOption, CoverageSPOT, Exposure } from '../../store/actions/transactions'
import { translateTransactions } from '../../utils/translateFunctions'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import TransactionsDeleteDialog from './TransactionsDeleteDialog'


function TransactionsDelete() {

    // Selected Transactions
    const selectedTransactions = RefApplicationParameterHook(con.SELECTED_TRANSACTIONS)

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)

    
    

    // Delete all dialog
    const [showDeleteAllDialog, setShowDeleteAllDialog] = useState(() => false)
    const [showDeleteCurrentDialog, setShowDeleteCurrentDialog] = useState(() => false)

  const deleteAllSelectedTransactions = () =>
  {
    if(selectedTransactions === con.EXPOSURES)
      Exposure.deleteAll()

    if(selectedTransactions === con.COVERAGES_FWD)
      CoverageFWD.deleteAll()

    if(selectedTransactions === con.COVERAGES_OPTION)
      CoverageOption.deleteAll()

    if(selectedTransactions === con.COVERAGES_SPOT)
      CoverageSPOT.deleteAll()

    if(selectedTransactions === con.ACCOUNTS)
      Account.deleteAll()

      setShowDeleteCurrentDialog(false)

  }

  const deleteAllTransactions = () =>
  {
      
      Exposure.deleteAll()
      CoverageFWD.deleteAll()
      CoverageOption.deleteAll()
      CoverageSPOT.deleteAll()
      Account.deleteAll()
      setShowDeleteAllDialog(false)

  }


  return (
    <div>

        <Modal show={showDeleteAllDialog} doAccept={null} showCancel={false} doCancel={(e) => setShowDeleteAllDialog(false)}>
              <TransactionsDeleteDialog 
                title="¿Desea Eliminar Todos los Registros?"
                deleteAction={() => deleteAllTransactions()}
                cancelAction={() => setShowDeleteAllDialog(false)} />              
        </Modal>
        <Modal show={showDeleteCurrentDialog} doAccept={null} showCancel={false}  doCancel={(e) => setShowDeleteCurrentDialog(false)}>
              <TransactionsDeleteDialog 
                title={`¿Desea ${selectedTransactions !== con.ACCOUNTS ? "Eliminar" : "Vaciar"} Todas las ${translateTransactions(selectedTransactions, clientType)}?`}
                deleteAction={() => deleteAllSelectedTransactions()}
                cancelAction={() => setShowDeleteCurrentDialog(false)} />  
        </Modal>        

        <div className="columnParameterBox">  
                  <div style={{marginBottom : '1vh'}}>
                    <Button text={`${selectedTransactions !== con.ACCOUNTS ? "Eliminar" : "Vaciar"} ${translateTransactions(selectedTransactions, clientType)}`}  icon="cross" onClick={(e) => setShowDeleteCurrentDialog(true)}/>
                  </div>
                  <div> 
                    <Button text={`Eliminar Todo`}  icon="cross" onClick={(e) => setShowDeleteAllDialog(true)} />
                  </div>
              </div>

    </div>
  )
}

export default TransactionsDelete