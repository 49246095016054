import React, { useState, useEffect } from 'react';
import { Calculator } from '../components/forwardsCalculator/Calculator';
import ForwardCurveTable from '../components/forwardsCalculator/ForwardCurveTable';
import SimpleFillFooter from '../templates/footer/SimpleFillFooter';
import "./MobilePages.css";

function MobileCalculatorPage() {
    const rowsToShow = [
        'title',
        'instrument',
        'type',
        'executionDate',
        'spot',
        'nominal',
        'daysToExpiry',
        'expirationDate',
        'referenceDevaluation'
    ];

    const [spacing, setSpacing] = useState(0);

    useEffect(() => {
        const updateSpacing = () => {
            const viewportHeight = window.innerHeight;
            const newSpacing = viewportHeight * 0.1; 
            setSpacing(newSpacing);
        }

        updateSpacing();

        window.addEventListener('resize', updateSpacing);

        return () => window.removeEventListener('resize', updateSpacing);
    }, []);

    return (
        <div className='centered-component' style={{ paddingTop: "20px", flexDirection: 'column' }}>
            <ForwardCurveTable />
            <div style={{ height: `${spacing}px` }}></div>
            <Calculator numberOfColumns={1} shortColumnNames={true} rowsToShow={rowsToShow} />
            <SimpleFillFooter />
        </div>
    );
}

export default MobileCalculatorPage;
