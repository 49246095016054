// TODO - Documentar
import React from 'react';
import { ForwardsTableColumnNames , ForwardsTableColumn} from "./ForwardsTableColumn"

/**
 * Componente que se encarga de la tabla con las comparaciones de forwards
 */
export const ForwardsTable = ({numberOfColumns = 3, shortColumnNames=false, rowsToShow}) => {
  return (
      <div style={{width : "90%", marginLeft : "5%", marginRight : "5%", marginTop : "5px"}}>
          <div className="tightTable">
              <ForwardsTableColumnNames shortColumnNames={shortColumnNames} rowsToShow={rowsToShow}/>
              {
                  [...Array(numberOfColumns).keys()].map(i => <ForwardsTableColumn numInstrumento={i+1} rowsToShow={rowsToShow} /> )
              }
          </div>
      </div>
  );
};

