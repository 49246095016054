import React from 'react';
import { CellText } from '../../templates/tables/Cells';

const formatPeriod = (period) => {
    switch (period) {
        case 'market_5_days':
            return '5 días';
        case 'market_10_days':
            return '2 semanas';
        case 'market_30_days':
            return '1 mes';
        case 'market_90_days':
            return '3 meses';
        case 'market_180_days':
            return '6 meses';
        case 'market_360_days':
            return '1 año';
        case 'market_total_days':
            return 'Histórico';
        default:
            return period;
    }
};

function MarketSummaryRow({ rowId, data, padding, minWidth }) {
    const formattedVolatility = data.volatilidad != null ? `${(data.volatilidad * 100).toFixed(2)}%` : " ";
    const formattedTasaPromedio = data.tasaPromedio != null ? data.tasaPromedio : " ";
    const formattedVolPromedio = data.volPromedio != null ? data.volPromedio : " ";
    const formattedMax = data.max != null ? data.max : " ";
    const formattedMin = data.min != null ? data.min : " ";

    return (
        <div className={`flex-fila hoverable-row ${(rowId % 2 === 0) ? "even-row" : "uneven-row"}`} style={{ padding: '1px 0' }}>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formatPeriod(data.periodo)} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formattedVolatility} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formattedTasaPromedio} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formattedVolPromedio} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formattedMax} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding, minWidth }}>
                <CellText value={formattedMin} editing={false} />
            </div>
        </div>
    );
}

export default MarketSummaryRow;
