// src/components/results/RiskComparisonForSingleScenario.js

import React, { useCallback, useEffect, useState } from 'react'
import { GenericElement } from '../../templates/FormElement'

// Importaciones de constantes y funciones
import * as con from "./../../GlobalConstants"
import { getTotalVars } from '../../utils/transactionFunctions'
import { Card, Elevation } from '@blueprintjs/core'

import "../ComponentsGlobalStyle.css"
import "./RiskComparisonForTwoScenarios.css" // Puedes renombrar este archivo CSS si lo prefieres
import { round } from '../../GlobalFunctions'
import { LABELS, NAME, TITLE, VALUES, Y_LABEL } from '../../templates/charts/ChartConstants'
import { MultiBarChartHook } from '../../templates/charts/hooks/MultiBarChartHook'
import { AllModelParameterHook } from '../hooks/ModelParameterHooks'
import { ScenariosHook } from '../hooks/ScenariosHook'
import { AccountsHook, ExposuresHook, ForwardCoveragesHook, OptionCoveragesHook } from '../hooks/TransactionsHooks'
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { MediumLabel, SmallLabel } from '../../templates/label/Labels'
import { formatAccordingToType } from '../../templates/Types'

function RiskComparisonForSingleScenario() {

    // Escenarios posibles
    const scenarios = ScenariosHook()

    // Transacciones    
    const exposures = ExposuresHook()
    const fwdCoverages = ForwardCoveragesHook()
    const optionCoverages = OptionCoveragesHook()
    const accounts = AccountsHook()

    // Parámetros del modelo
    const modelParameters = AllModelParameterHook()
    const spot = RefSPOT()
    const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

    // Parámetros de aplicación del modelo
    const modelApplicationParameters = RefModelApplicationParameterHook()

    // Escenarios calculados
    const [computedScenarios, setComputedScenarios] = useState(() =>  Object.assign({}, ...Object.keys(scenarios).map((id) => ({[id]: null})))) 

    // Escenario seleccionado
    const [scenarioId, setScenarioId] = useState(() => con.SCENARIO_VAR_UP)
    const [scenario, setScenario] = useState(() => null)

    // Hook del gráfico
    const [, chart] = MultiBarChartHook({ 
        parameters: { 
            [Y_LABEL]: "Millones COP", 
            [TITLE]: "Escenario de riesgo"
        } 
    });

    useEffect(() => {

        if(!chart.initialized)
            chart.build(getDefautlData())

    }, [chart])

    // Actualiza los escenarios calculados cuando cambian las dependencias
    useEffect(() => {

        // Resetea los escenarios calculados
        setComputedScenarios(Object.assign({}, ...Object.keys(scenarios).map((id) => ({[id]: null}))))

        // Resetea el escenario seleccionado
        setScenario(null)

    }, [scenarios, exposures, fwdCoverages, optionCoverages, accounts, modelParameters])

    // Función para actualizar un escenario
    const updateScenario =  useCallback((id) =>
    {

        setScenarioId(id)
        setScenario(computedScenarios[id])

        if(computedScenarios[id] === null)
        {

            new Promise(function (success, error) {

                setTimeout(() => {
                    let totVars = getTotalVars(
                        currentDate,
                        spot,
                        scenarios[id].gen_fun,
                        modelParameters, 
                        exposures,
                        fwdCoverages,
                        optionCoverages,
                        accounts,
                        modelApplicationParameters)

                    success(totVars)
                }, con.UI_TIMEOUT)


            }).then((totVars) => {

                if(totVars !== null)
                {
                    totVars = Object.assign({}, ...Object.keys(totVars).map((key) => ({[key]: totVars[key]/con.MILLION})))
                    computedScenarios[id] = totVars 
                    setScenario(totVars)
                }

            })                        


        }

    }, [scenarios, exposures, fwdCoverages, optionCoverages, accounts, modelParameters, computedScenarios, currentDate, spot, modelApplicationParameters])

    useEffect(() => {

        // Función para generar etiquetas
        const makeLabel = (var_id, band_name) =>
        {
            return(`${scenarios[scenarioId][NAME]}: ${round(scenario[var_id],2)} COP (${band_name})`)

        }

        if(!chart.initialized)
            chart.initialize()

        if(scenario == null)
            updateScenario(scenarioId)

        let chartData = []
        if(scenario != null)
        {

            chartData = [{[VALUES] : [scenario[con.VAR]], [LABELS]: [makeLabel(con.VAR, "Var Oportunístico")]} ,
                        {[VALUES] : [scenario[con.LOWER_BAND+"_"+con.VAR]], [LABELS]: [makeLabel(con.LOWER_BAND+"_"+con.VAR, "Banda Baja")]},            
                        {[VALUES] :[scenario[con.MID_BAND+"_"+con.VAR]], [LABELS]: [makeLabel(con.MID_BAND+"_"+con.VAR, "Banda Media")]},
                        {[VALUES] : [scenario[con.HIGHER_BAND+"_"+con.VAR]], [LABELS]:[makeLabel(con.HIGHER_BAND+"_"+con.VAR, "Banda Alta")]}]


            chart.updateData(chartData)
        }


    }, [scenario, scenarioId, chart, updateScenario, scenarios])

    return (

        <div>                       
            <div className="cardsAndChart">
                <div>
                < GenericElement type={con.MULTIPLE_SELECTION}
                            name={"Escenario VaR"}
                            id={"escenario"} 
                            value={scenarioId} 
                            setValue={(val) => updateScenario(val)} 
                            values={Object.keys(scenarios)}
                            valueLabels={Object.values(scenarios).map((esc) => {return(esc.name)})}
                            />   
                    <div className="cardBox scenario-1">
                        
                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Oportunístico" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario[con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})} />
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Alta" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario[con.HIGHER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Media" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario[con.MID_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Baja" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario[con.LOWER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>
                    </div>
                </div>          
            </div>
        </div>
    )
}

const getDefautlData = () =>
{
    return( [{ [NAME] :'Var Oportu.',  [VALUES] : [100], [LABELS]: [""]},        
    { [NAME] :'Banda Baja',  [VALUES] : [100], [LABELS]: [""]},
    { [NAME] :'Banda Media',  [VALUES] : [100], [LABELS]: [""]},
    { [NAME] :'Banda Alta',  [VALUES] : [100], [LABELS]: [""]}])
}

export default RiskComparisonForSingleScenario
