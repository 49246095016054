// TODO - Documentar
import React from 'react'


// Style
import '../ComponentsGlobalStyle.css'
import * as con from '../../GlobalConstants'
import { AverageForPercentage, BandSelect, NonZeroRowsCheckBox, ScenariosSelect, VisualizationPeriodicity } from './SingleParameters';
import { RefRole } from '../hooks/AuthenticationHook';


function BandPositionParameters() {
    
    const role = RefRole()
    const isNotViewer = role !== con.VIEWER

    return (
    <div>
        <div className='bigParameterBox' >                                                                                                                               
            {isNotViewer && <AverageForPercentage/>}
            <NonZeroRowsCheckBox />                      
            <VisualizationPeriodicity/>   
            <ScenariosSelect />             
        </div>
        <div className="gridBox" style={{ paddingLeft : '15px', paddingRight : '15px'}}>      
            <div style={{paddingLeft : "30%"}}>  
              <BandSelect/>
            </div>
        </div>
      </div>
    )
}

export default BandPositionParameters
