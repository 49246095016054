// src/pagesMobile/MobileReportsPage.js

import React from 'react'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import "./MobilePages.css"
import MobileReportMenu from '../components/reports/MobileReportMenu'

function MobileReportsPage() {
    return (
        <div style={{ minHeight: USABLE_SCREEN_SIZE + "vh" }}>
            <MobileReportMenu />
        </div>
    )
}

export default MobileReportsPage
