// src/pagesMobile/MobileRiskComparisonPage.js

import React from 'react'
import RiskComparisonForSingleScenario from '../components/results/RiskComparisonForSingleScenario'

function MobileRiskComparisonPage() {
    return (
        <div>
            <RiskComparisonForSingleScenario />
        </div>
    )
}

export default MobileRiskComparisonPage
