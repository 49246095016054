// TODO - Documentar
import React from 'react';
import { ForwardsTable } from "./ForwardsTable"



export const Calculator = ({numberOfColumns = 3, shortColumnNames=false, rowsToShow}) => {
    return (
        <div>
            <ForwardsTable numberOfColumns={numberOfColumns} shortColumnNames={shortColumnNames} rowsToShow={rowsToShow}/>
        </div>
    );
};

