// TODO - Documentar
// Hooks for the diffetent transactions
import { useCallback, useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'


import * as con from "../../GlobalConstants"
import { filterObject, size } from '../../GlobalFunctions'
import { parseDate } from '../../utils/dateFunctions'
import { getFWDAssessmentData } from '../../utils/forwardFunctions'
import { allTransactionsCol } from '../../utils/transactionFunctions'
import { EditApplicationParameterHook, RefApplicationParameterHook } from './ApplicationParametersHooks'
import { getOptionAssessmentData } from '../../utils/optionFunctions'
import { getCurrentLine } from '../../store/logic/supportFunctions'

export const ExposuresHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_EXPOSURES])
    return(trans)
}

export const ForwardCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_FWD_COVERAGES])
    return(trans)
}


export const SpotCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_SPOT_COVERAGES])
    return(trans)
}

export const OptionCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_OPTION_COVERAGES])
    return(trans)
}

export const AccountsHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_ACCOUNTS])
    return(trans)
}


export const TransactionColumnsHook = (selectedColumns, filterByBands) =>
{

    const selectedBand = RefApplicationParameterHook(con.SELECTED_BAND)

    const [usedColumns, setUsedColumns] = useState( () => selectedColumns.filter(col => !col[con.ID].includes("BAND") ||
                                                                                         !filterByBands ||
                                                                                         col[con.ID].includes(selectedBand))
                                                                          .map(col => {return({...allTransactionsCol[col[con.ID]], ...col})}))
    useEffect(() => {

        setUsedColumns(selectedColumns.filter(col => !col[con.ID].includes("BAND") ||
                                                !filterByBands ||
                                                col[con.ID].includes(selectedBand))
                                        .map(col => {return({...allTransactionsCol[col[con.ID]], ...col})}))

    }, [selectedColumns, filterByBands, selectedBand])


    return(usedColumns)
}


export const HasActivePurchases = (startDate, endDate, operation, clientType) =>
{
    const fwdCoverages = ForwardCoveragesHook()
    const spotCoverages = SpotCoveragesHook()
    const optionCoverages = OptionCoveragesHook()

    // Has purchases
    const [hasCoverages, setHasCoverages] = useState(() => false)

    useEffect(() => {

        let startDateOb = parseDate(startDate)
        let endDateOb = parseDate(endDate)
        let avSpot = {}

        if((clientType === con.IMPORTER && operation === con.BUY) || (clientType === con.EXPORTER && operation === con.BUY)){
            avSpot = filterObject(spotCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb)
        }

        let avFwd = filterObject(fwdCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb && cov[con.COVERAGE_TYPE] === operation)
        let avOption = filterObject(optionCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb && cov[con.COVERAGE_TYPE] === operation)

        setHasCoverages(size(avFwd) > 0 || size(avSpot) > 0 || size(avOption) > 0)

    }, [fwdCoverages, spotCoverages, optionCoverages, startDate, endDate, operation, clientType])

    return(hasCoverages)

}


export const AssessedForwardCoveragesHook = () =>
{
    
    // Forwards
    const forwards = ForwardCoveragesHook()

    // Rate
    const forward_assessment_rate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_RATE)

    // Reference Date
    const referenceDate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_DATE)

    // FWD filter Function
    const fwd_assessment_filter_fun = RefApplicationParameterHook(con.FWD_ASSESSMENT_FILTER_FUN)

    // Current Line
    const userline = getCurrentLine();

    const setLoading = useCallback((status) => {
        EditApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)(status);
    }, []);

    const default_summary = useMemo(() => ({
        [con.ACTIVE_FWD_AMOUNT]: 0,
        [con.AVERAGE_FWD_RATE]: 0,
        [con.ASSESSMENT_RATE]: 0,
        [con.PORTFOLIO_ASSESSMENT]: 0
    }), []);

    const [assessedFwd, setAssessedFwd] = useState({});
    const [assessmentSummaries, setAssessmentSummaries] = useState(default_summary);
    
    useEffect(() => {
        const fetchFWDAssessmentData = async () => {
            try {
                const forward_list = Object.values(forwards).filter(fwd => fwd_assessment_filter_fun(fwd));
                if (forward_list.length >= 1 && forward_list[0][con.USER_LINE] === Number(userline)) {
                    setLoading(true)
                    const data = { forward_list, referenceDate, forward_assessment_rate };
                    const response = await getFWDAssessmentData(data);
                    if (response) {
                        const responseData = response[con.DATA][con.DATA];
                        const transformedData = responseData[con.COVERAGES_FWD].reduce((acc, obj) => {
                            acc[obj.id] = obj;
                            return acc;
                        }, {});
                        setAssessedFwd(transformedData);
                        setAssessmentSummaries(responseData[con.SUMMARY]);
                    }
                } else {
                    setAssessedFwd({});
                    setAssessmentSummaries(default_summary)
                }
            } catch (error) {
                console.error('Error fetching FWD Assessment data:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchFWDAssessmentData();
    }, [forwards, fwd_assessment_filter_fun, referenceDate, forward_assessment_rate, userline, setLoading, default_summary]);


    return([assessedFwd, assessmentSummaries])
    
}

export const AssessedOptionCoveragesHook = () => {
    
    // Options
    const options = OptionCoveragesHook()

    // USDCOP Rate to valuing options 
    const option_assessment_rate = RefApplicationParameterHook(con.OPTION_ASSESSMENT_RATE)

    // Reference Date
    const referenceDate = RefApplicationParameterHook(con.OPTION_ASSESSMENT_REFERENCE_DATE)

    // Option Filter Function
    const option_assessment_filter_function = RefApplicationParameterHook(con.OPTION_ASSESSMENT_FILTER_FUN)

    // Current Line
    const userline = getCurrentLine();
    
    const setLoading = useCallback((status) => {
        EditApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)(status);
    }, []);

    // Assessed Options Coverages
    const [assessedOptionsCoverages, setAssessedOptionsCoverages] = useState({})

    // Assessment Summary
    const default_summary = useMemo(() => ({
        [con.ACTIVE_OPTION_AMOUNT] : 0,
        [con.ASSESSMENT_RATE] : 0,
        [con.PREMIUM_GAINS] : 0,
        [con.PORTFOLIO_ASSESSMENT] : 0,
        [con.TOTAL_ASSESSMENT] : 0
    }), []);

    const [assessmentSummary, setAssessmentSummary] = useState(default_summary)


    // Updates Assessed Coverages
    useEffect(() => {
        const fetchFWDAssessmentData = async () => {
            try {
                const option_list = Object.values(options).filter(op => option_assessment_filter_function(op));
                if (option_list.length >= 1 && option_list[0][con.USER_LINE] === userline) {
                    setLoading(true)
                    const data = { option_list, referenceDate, option_assessment_rate };
                    const response = await getOptionAssessmentData(data);
                    if (response) {
                        const responseData = response[con.DATA][con.DATA];
                        const transformedData = responseData[con.COVERAGES_OPTION].reduce((acc, obj) => {
                            acc[obj.id] = obj;
                            return acc;
                        }, {});
                        setAssessedOptionsCoverages(transformedData);
                        setAssessmentSummary(responseData[con.SUMMARY]);
                    } else {
                        setAssessedOptionsCoverages({})
                        setAssessmentSummary(default_summary)
                    }
                }
            } catch (error) {
                console.error('Error fetching Option Assessment data:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchFWDAssessmentData();
    }, [options, option_assessment_filter_function, referenceDate, option_assessment_rate, userline, setLoading, default_summary]);



    return([assessedOptionsCoverages, assessmentSummary])
}


